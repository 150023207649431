import React from 'react';
import { Col } from 'react-bootstrap';

const ListingStatus = ({ salesData }) => {

    if (!salesData || !salesData.payment) {
        // return ;
    }

    const statusList = [
        { key: 'item_listed', label: 'Listed', dateKey: 'item_listed_date' },
        { key: 'item_sold', label: 'Confirmed', dateKey: 'item_sold_date' },
        { key: 'buyer_paid', label: 'Paid', dateKey: 'buyer_paid_date' },
        { key: 'seller_dispached', label: 'Shipped', dateKey: 'seller_dispached_date' },
        { key: 'delivered', label: 'Complete', dateKey: 'delivered_date' }
    ];

    return (
        <Col lg={3}>
            {salesData?.payment != null ?
                <div className="card">
                    <div className="card-body listing-details-card">
                        <h2>Status</h2>
                        <div className="listing-status-contain">
                            <ul>
                                {statusList.map((status, index) => {
                                    const isActive = salesData?.payment[status.key] === 1;
                                    // const date = isActive ? new Date(salesData.payment[status.dateKey]).toLocaleDateString() : '--/--/--';
                                    const date = salesData?.payment[status.dateKey] ? new Date(salesData.payment[status.dateKey]).toLocaleDateString() : '--/--/--';
                                    const itemClass = isActive ? 'ad-green-bg' : 'ad-grey-bg';
                                    const circleColor = isActive ? '#CCD2E3' : '#B0BBD5';
                                    const strokeColor = isActive ? '#CCD2E3' : '#B0BBD5';


                                    const isProgress = isActive && (
                                        (index < statusList.length - 1 && salesData.payment[statusList[index + 1].key] === 1)
                                    );

                                    const isDisputed = salesData?.payment?.disputed === 1 && index === statusList.length - 1;

                                    return (
                                        <li key={index} className={itemClass}>
                                            <div className="list-item-contain">
                                                <div className="list-left-data">
                                                    <div className={`left-circle ${isProgress ? 'ad-progress' : 'ad-grey'}`}></div>
                                                    <div>
                                                        <p>{status.label}</p>
                                                        <span>{date}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <svg
                                                        width="41"
                                                        height="41"
                                                        viewBox="0 0 41 41"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="20.5"
                                                            cy="20.5"
                                                            r="15.375"
                                                            fill={circleColor}
                                                            stroke={strokeColor}
                                                        />
                                                        {isActive && index <= statusList.length - 1 && (
                                                            <path
                                                                d="M13.6666 20.5L18.7916 25.625L27.3333 15.375"
                                                                stroke="#CCD2E3"
                                                                strokeWidth="2"
                                                            />
                                                        )}
                                                    </svg>
                                                </div>


                                            </div>
                                        </li>
                                    );
                                })}

                                {salesData?.payment?.disputed === 1 && (
                                    <li className="ad-red-bg">
                                        <div className="list-item-contain">
                                            <div className="list-left-data">
                                                <div className="left-circle ad-red" style={{backgroundColor: "#FF0000"}}></div>
                                                <div>
                                                    <p>Disputed</p>
                                                    <span>Not Resolved</span>
                                                </div>
                                            </div>
                                            <div>
                                                <svg
                                                    width="41"
                                                    height="41"
                                                    viewBox="0 0 41 41"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="20.5"
                                                        cy="20.5"
                                                        r="15.375"
                                                        fill="#B0BBD5"
                                                        stroke="#B0BBD5"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                :
                <div className="card">
                    <div className="card-body listing-details-card">
                        <h2>Status</h2>
                        <div className="listing-status-contain add-new-height">
                            <ul>
                                <li >
                                    <div className="list-item-contain">
                                        <div className="list-left-data">
                                            <p>The user has multiple quantities of this item.
                                                See the sales section for details.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
            }


        </Col>
    );
};

export default ListingStatus;
